import React from "react";

const EmailSendedFunctions = ({
  emailSended,
  emailReceived,
  emailError,
  progetto,
}) => {
  return (
    <>
      {/* ----------- Email Sending ... Animation  -----------*/}
      <div
        className={`h-100 w-100 text-center bg-white d-flex align-items-center justify-content-center
        ${!emailSended && "d-none"}`}
      >
        {emailSended && (
          <>
            <div
              className='container-email-sending'
              style={{
                "::after": {
                  color: `${progetto.primaryColor}`,
                },
              }}
            >
              <div className='box-email-sending'>
                <div
                  className='border-email-sending one'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>
                <div
                  className='border-email-sending two'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>
                <div
                  className='border-email-sending three'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>
                <div
                  className='border-email-sending four'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>

                <div
                  className='line-email-sending one'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>
                <div
                  className='line-email-sending two'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>
                <div
                  className='line-email-sending three'
                  style={{ background: `${progetto.primaryColor}` }}
                ></div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* ----------- Email Success Animation  -----------*/}
      <div
        className={`h-100 w-100 text-center bg-white d-flex flex flex-column align-items-center justify-content-center
        ${!emailReceived && "d-none"}`}
        id='email_received'
      >
        {emailReceived && (
          <>
            <div className='success-animation d-flex flex-column align-items-center'>
              <p
                className='email-recived-text'
                style={{ color: `${progetto && progetto.primaryColor}` }}
              >
                Dati ricevuti correttamente
              </p>
              <svg
                className='checkmark'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 52 52'
                style={{ stroke: `${progetto && progetto.primaryColor}` }}
              >
                <circle
                  className='checkmark__circle'
                  cx='26'
                  cy='26'
                  r='25'
                  fill='none'
                  style={{ stroke: `${progetto && progetto.primaryColor}` }}
                />
                <path
                  className='checkmark__check'
                  fill='none'
                  d='M14.1 27.2l7.1 7.2 16.7-16.8'
                />
              </svg>
            </div>
          </>
        )}
      </div>

      {/* ----------- Email Error Animation  -----------*/}
      <div
        className={`h-100 w-100 text-center bg-white align-items-center justify-content-center
        email-error-div d-flex
  ${!emailError && "d-none"}`}
      >
        {emailError && (
          <div>
            <div className='h-auto'>
              {" "}
              <p className='error-text'>
                C'è stato un problema con l'invio dei dati
              </p>
            </div>
            <div className='d-block'>
              {" "}
              {/* Aggiunto d-block */}
              <div className='d-flex justify-content-center align-items-start'>
                <div className='circle-border'></div>
                <div className='circle'>
                  <div className='error'></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EmailSendedFunctions;
