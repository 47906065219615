import React, { useState, createContext } from "react";

export const StateContext = createContext();

const StateContainer = ({ children }) => {
  const [typeWriteText, setTypeWriteText] = useState("");
  const [error, setError] = useState();
  const [leadCode, setLeadCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [nascondiSidebar, setNascondiSidebar] = useState(false);
  const [listOfProjects, setListOfProjects] = useState([]);
  const [dominioDelSito, setDominioDelSito] = useState("");
  const [displayElements, setDisplayElements] = useState([]);
  const [nuovoProgetto, setNuovoProgetto] = useState([]);
  const [aggiornamentoDatiProgetto, setAggiornamentoDatiProgetto] =
    useState(null);
  const [dettagliProgetto, setDettagliProgetto] = useState([]);
  const [message, setMessage] = useState("");
  const [showFormRequestContacts, setShowFormRequestContacts] = useState(false);
  const [currentIndexTypeWrite, setCurrentIndexTypeWrite] = useState(0);
  const [isTypingMachine, setIsTypingMachine] = useState("");
  const [showFormContactFollowChat, setShowFormContactFollowChat] = useState(false)

  return (
    <StateContext.Provider
      value={{
        message,
        setMessage,
        dettagliProgetto,
        setDettagliProgetto,
        aggiornamentoDatiProgetto,
        setAggiornamentoDatiProgetto,
        dominioDelSito,
        setDominioDelSito,
        nuovoProgetto,
        setNuovoProgetto,
        nascondiSidebar,
        setNascondiSidebar,
        loading,
        setLoading,
        error,
        setError,
        setListOfProjects,
        listOfProjects,
        showFormRequestContacts,
        setShowFormRequestContacts,
        leadCode,
        setLeadCode,
        typeWriteText,
        setTypeWriteText,
        currentIndexTypeWrite,
        setCurrentIndexTypeWrite,
        isTypingMachine,
        setIsTypingMachine,

        // Attivare e disattivare la form di contatto nel momento in cui l'utente scrive
        showFormContactFollowChat, setShowFormContactFollowChat
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateContainer;
