import React, { useContext } from "react";
import { StateContext } from "../../StateContainer";
import AvatarPink from "../../../img/avatarPink.png";
// import { TbDotsVertical as Dots, TbH1 } from "react-icons/tb";
import Moment from "react-moment";
import { Chart } from "react-google-charts";
import { marked } from "marked";  // Importa la libreria marked

const SqlChart = ({ row, primaryRGBA }) => {
  const { dettagliProgetto } = useContext(StateContext);
  const options = {
    title: row.botMessage.optionsGraphic?.title,
    hAxis: { title: row.botMessage.optionsGraphic?.hAxisTitle, titleTextStyle: { color: "#333" } },
    vAxis: { title: row.botMessage.optionsGraphic?.yAxisTitle, minValue: 0 },
    chartArea: { width: "50%", height: "70%" },
  };
  


  return (
    <li className="pb-5 message-bot" >
      <div className="d-flex align-items-start gap-2" style={{width: "100%"}}> 
        <div
          style={{
            height: "2.4vw",
            width: "2.4vw",
            minHeight: "16px",
            minWidth: "16px",
            borderRadius: "100%",
            background: `url(${
              dettagliProgetto && dettagliProgetto.chatbotIcon
                ? dettagliProgetto.chatbotIcon
                : AvatarPink
            }) center center / cover no-repeat`,
          }}
          className=""

        ></div>
        <div style={{width: "100%"}}>
          <h5 className="font-size-14 conversation-name">
            <a href="#" className="text-dark text-decoration-none">
              {dettagliProgetto ? dettagliProgetto.assistantName : ""}
            </a>{" "}
          </h5>

          <div className="conversation-list moveUpAnimation" style={{width: "100%"}}>
          <Chart
            className="w-100"
            chartType={row.botMessage.optionsGraphic?.chartType}
            width="100%"
            height="auto"
            data={row.botMessage.dataGraphic}
            options={options}
          />
          
          <div
              className="conversation-list m-0"
              style={{ maxWidth: "400px" }}
            >
              <div className="ctext-wrap mt-2 m-0">
                <div
                  className="ctext-wrap-content m-0 text-dark mb-1"
                  style={{
                    background: `${primaryRGBA}`,
                    maxWidth: "300px",
                    hyphens: "auto",
                  }}
                >
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: marked(row.botMessage.link_gestionale).replace(/<a /g, "<a target='_blank' "), // Aggiungi target='_blank' qui
                    }}
                  ></div>
                </div>
              </div>
            </div>


            {/* <div>
                <p dangerouslySetInnerHTML={{__html: row.botMessage.link_gestionale}}></p>
            </div> */}
            <span className="d-inline-block font-size-12 text-muted">
              <Moment format="HH:mm">{row.botMessage.date}</Moment>
            </span>
          
          </div>
        </div>
      </div>
    </li>
  );
};

export default SqlChart;
