import { useLocation } from "react-router-dom";
import StateContainer from "./components/StateContainer";
import RoutesSite from "./Routes";

function App() {
  return (
    <StateContainer>
      <div className='App'>
        <RoutesSite />
      </div>
    </StateContainer>
  );
}

export default App;
