import React, { useState, useEffect, useContext } from "react";

import { StateContext } from "../StateContainer";

const useSpeechRecognition = () => {
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState("");

  const { setMessage } = useContext(StateContext);

  useEffect(() => {
    const newRecognition = new window.webkitSpeechRecognition();

    newRecognition.onresult = (event) => {
      setText(event.results[0][0].transcript);
      setMessage((prev) => prev + " " + event.results[0][0].transcript);

      setIsListening(false);
      newRecognition.stop();
    };

    setRecognition(newRecognition);
  }, []);

  const startListening = () => {
    setText("");
    setIsListening(true);
    recognition.start();
  };

  const stopListening = () => {
    setIsListening(false);
    recognition.stop();
  };

  return {
    text,
    isListening,
    stopListening,
    startListening,
    hasRecognitionSupport: !!recognition,
  };
};

export default useSpeechRecognition;

// import React, { useState, useEffect, useContext } from "react";

// import { StateContext } from "../StateContainer";

// const useSpeechRecognition = () => {
//   const { message, setMessage } = useContext(StateContext);

//   const [text, setText] = useState("");
//   const [isListening, setIsListening] = useState(false);
//   let recognition = new window.webkitSpeechRecognition();
//   recognition.continuous = true;
//   recognition.lang = "it-IT";
