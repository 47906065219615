import React from "react";
import { useEffect, useRef } from "react";
// import ReDesiderio from "../img/ReDesiderio.png";

const VideoAssistente = () => {
  //   let DID_API = {
  //     url: "https://api.d-id.com",
  //     service: "talks",
  //     key: "zzz:i6oXx8PKRXo10nfemStqR",
  //   };

  //   const RTCPeerConnection = (
  //     window.RTCPeerConnection ||
  //     window.webkitRTCPeerConnection ||
  //     window.mozRTCPeerConnection
  //   ).bind(window);

  //   let peerConnection;
  //   let streamId;
  //   let sessionId;
  //   let sessionClientAnswer;

  //   let statsIntervalId;
  //   let videoIsPlaying;
  //   let lastBytesReceived;

  //   const videoElement = document.getElementById("video-element");
  //   if (videoElement) {
  //     videoElement.setAttribute("playsinline", "");
  //     videoElement.play();
  //     // videoElement.play();
  //   }
  //   const peerStatusLabel = document.getElementById("peer-status-label");
  //   const iceStatusLabel = document.getElementById("ice-status-label");
  //   const iceGatheringStatusLabel = document.getElementById(
  //     "ice-gathering-status-label"
  //   );
  //   const signalingStatusLabel = document.getElementById(
  //     "signaling-status-label"
  //   );
  //   const streamingStatusLabel = document.getElementById(
  //     "streaming-status-label"
  //   );

  //   const presenterInputByService = {
  //     talks: {
  //       source_url:
  //         "https://app.tailorgpt.ai/demo/brescia-musei/ReDesiderio_cropped.jpeg",
  //     },
  //     clips: {
  //       presenter_id: "rian-lZC6MmWfC1",
  //       driver_id: "mXra4jY38i",
  //     },
  //   };

  //   const startButton = async (message) => {
  //     // Controlla se la connessione peer è già attiva
  //     if (peerConnection && peerConnection.connectionState === "connected") {
  //       return;
  //     }

  //     // // Interrompe tutti gli stream attivi e chiude la connessione peer
  //     stopAllStreams();
  //     closePC();

  //     // Crea una nuova sessione con il servizio D-ID
  //     const sessionResponse = await fetchWithRetries(
  //       `${DID_API.url}/${DID_API.service}/streams`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Basic ${DID_API.key}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(presenterInputByService[DID_API.service]),
  //       }
  //     );

  //     const {
  //       id: newStreamId,
  //       offer,
  //       ice_servers: iceServers,
  //       session_id: newSessionId,
  //     } = await sessionResponse.json();

  //     streamId = newStreamId;
  //     sessionId = newSessionId;

  //     try {
  //       sessionClientAnswer = await createPeerConnection(offer, iceServers);
  //     } catch (e) {
  //       console.log("error during streaming setup", e);
  //       stopAllStreams();
  //       closePC();
  //       return;
  //     }

  //     // Invia la risposta della sessione al server
  //     const sdpResponse = await fetch(
  //       `${DID_API.url}/${DID_API.service}/streams/${streamId}/sdp`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Basic ${DID_API.key}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           answer: sessionClientAnswer,
  //           session_id: sessionId,
  //         }),
  //       }
  //     );

  //     // Verifica la stabilità della connessione peer o dello stato di connessione ICE
  //     if (
  //       peerConnection?.signalingState === "stable" ||
  //       peerConnection?.iceConnectionState === "connected"
  //     ) {
  //       // Avvia la riproduzione del video
  //       const playResponse = await fetchWithRetries(
  //         `${DID_API.url}/${DID_API.service}/streams/${streamId}`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Authorization: `Basic ${DID_API.key}`,
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             script: {
  //               type: "text",
  //               provider: {
  //                 type: "microsoft",
  //                 voice_id: "it-IT-DiegoNeural",
  //               },
  //               input: message,
  //             },
  //             ...(DID_API.service === "clips" && {
  //               background: {
  //                 color: "#FFFFFF",
  //               },
  //             }),
  //             config: {
  //               stitch: true,
  //             },
  //             session_id: sessionId,
  //           }),
  //         }
  //       );
  //     }
  //   };

  //   const destroyButton = async () => {
  //     // Elimina la sessione una volta terminata la riproduzione
  //     await fetch(`${DID_API.url}/${DID_API.service}/streams/${streamId}`, {
  //       method: "DELETE",
  //       headers: {
  //         Authorization: `Basic ${DID_API.key}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ session_id: sessionId }),
  //     });

  //     // Interrompe tutti gli stream e chiude la connessione peer
  //     stopAllStreams();
  //     closePC();
  //   };

  //   function onIceGatheringStateChange() {
  //     if (iceGatheringStatusLabel) {
  //       iceGatheringStatusLabel.innerText = peerConnection.iceGatheringState;
  //       iceGatheringStatusLabel.className =
  //         "iceGatheringState-" + peerConnection.iceGatheringState;
  //     }
  //   }
  //   function onIceCandidate(event) {
  //     console.log("onIceCandidate", event);
  //     if (event.candidate) {
  //       const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

  //       fetch(`${DID_API.url}/${DID_API.service}/streams/${streamId}/ice`, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Basic ${DID_API.key}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           candidate,
  //           sdpMid,
  //           sdpMLineIndex,
  //           session_id: sessionId,
  //         }),
  //       });
  //     }
  //   }
  //   function onIceConnectionStateChange() {
  //     if (iceStatusLabel) {
  //       iceStatusLabel.innerText = peerConnection.iceConnectionState;
  //       iceStatusLabel.className =
  //         "iceConnectionState-" + peerConnection.iceConnectionState;
  //       if (
  //         peerConnection.iceConnectionState === "failed" ||
  //         peerConnection.iceConnectionState === "closed"
  //       ) {
  //         stopAllStreams();
  //         closePC();
  //       }
  //     }
  //   }
  //   function onConnectionStateChange() {
  //     // not supported in firefox
  //     if (peerStatusLabel) {
  //       peerStatusLabel.innerText = peerConnection.connectionState;
  //       peerStatusLabel.className =
  //         "peerConnectionState-" + peerConnection.connectionState;
  //     }
  //   }

  //   function onSignalingStateChange() {
  //     if (signalingStatusLabel) {
  //       signalingStatusLabel.innerText = peerConnection.signalingState;
  //       signalingStatusLabel.className =
  //         "signalingState-" + peerConnection.signalingState;
  //     }
  //   }

  //   function onVideoStatusChange(videoIsPlaying, stream) {
  //     let status;
  //     if (videoIsPlaying) {
  //       status = "streaming";
  //       const remoteStream = stream;
  //       setVideoElement(remoteStream);
  //     } else {
  //       status = "empty";
  //       playIdleVideo();
  //     }
  //     if (streamingStatusLabel) {
  //       streamingStatusLabel.innerText = status;
  //       streamingStatusLabel.className = "streamingState-" + status;
  //     }
  //   }

  //   function onTrack(event) {
  //     /**
  //      * The following code is designed to provide information about wether currently there is data
  //      * that's being streamed - It does so by periodically looking for changes in total stream data size
  //      *
  //      * This information in our case is used in order to show idle video while no video is streaming.
  //      * To create this idle video use the POST https://api.d-id.com/talks (or clips) endpoint with a silent audio file or a text script with only ssml breaks
  //      * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
  //      * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
  //      */

  //     if (!event.track) return;

  //     statsIntervalId = setInterval(async () => {
  //       const stats = await peerConnection.getStats(event.track);
  //       stats.forEach((report) => {
  //         if (report.type === "inbound-rtp" && report.mediaType === "video") {
  //           const videoStatusChanged =
  //             videoIsPlaying !== report.bytesReceived > lastBytesReceived;

  //           if (videoStatusChanged) {
  //             videoIsPlaying = report.bytesReceived > lastBytesReceived;
  //             onVideoStatusChange(videoIsPlaying, event.streams[0]);
  //           }
  //           lastBytesReceived = report.bytesReceived;
  //         }
  //       });
  //     }, 500);
  //   }

  //   async function createPeerConnection(offer, iceServers) {
  //     if (!peerConnection) {
  //       peerConnection = new RTCPeerConnection({ iceServers });
  //       peerConnection.addEventListener(
  //         "icegatheringstatechange",
  //         onIceGatheringStateChange,
  //         true
  //       );
  //       peerConnection.addEventListener("icecandidate", onIceCandidate, true);
  //       peerConnection.addEventListener(
  //         "iceconnectionstatechange",
  //         onIceConnectionStateChange,
  //         true
  //       );
  //       peerConnection.addEventListener(
  //         "connectionstatechange",
  //         onConnectionStateChange,
  //         true
  //       );
  //       peerConnection.addEventListener(
  //         "signalingstatechange",
  //         onSignalingStateChange,
  //         true
  //       );
  //       peerConnection.addEventListener("track", onTrack, true);
  //     }

  //     await peerConnection.setRemoteDescription(offer);
  //     console.log("set remote sdp OK");

  //     const sessionClientAnswer = await peerConnection.createAnswer();
  //     console.log("create local sdp OK");

  //     await peerConnection.setLocalDescription(sessionClientAnswer);
  //     console.log("set local sdp OK");

  //     return sessionClientAnswer;
  //   }

  //   const setVideoElement = (stream) => {
  //     if (!stream) return;
  //     const videoElement = document.getElementById("video-element");
  //     videoElement.srcObject = stream;
  //     videoElement.loop = false;

  //     // Safari hotfix
  //     if (videoElement.paused) {
  //       videoElement
  //         .play()
  //         .then(() => {})
  //         .catch((e) => {});
  //     }
  //   };

  //   const playIdleVideo = () => {
  //     const videoElement = document.getElementById("video-element");
  //     videoElement.srcObject = undefined;
  //     videoElement.src =
  //       "https://app.tailorgpt.ai/demo/brescia-musei/ReDesiderio_idle.mp4";
  //     videoElement.loop = true;
  //   };

  //   function stopAllStreams() {
  //     const videoElement = document.getElementById("video-element");
  //     if (videoElement.srcObject) {
  //       console.log("stopping video streams");
  //       videoElement.srcObject.getTracks().forEach((track) => track.stop());
  //       videoElement.srcObject = null;
  //     }
  //   }

  //   function closePC(pc = peerConnection) {
  //     if (!pc) return;
  //     console.log("stopping peer connection");
  //     pc.close();
  //     pc.removeEventListener(
  //       "icegatheringstatechange",
  //       onIceGatheringStateChange,
  //       true
  //     );
  //     pc.removeEventListener("icecandidate", onIceCandidate, true);
  //     pc.removeEventListener(
  //       "iceconnectionstatechange",
  //       onIceConnectionStateChange,
  //       true
  //     );
  //     pc.removeEventListener(
  //       "connectionstatechange",
  //       onConnectionStateChange,
  //       true
  //     );
  //     pc.removeEventListener(
  //       "signalingstatechange",
  //       onSignalingStateChange,
  //       true
  //     );
  //     pc.removeEventListener("track", onTrack, true);
  //     clearInterval(statsIntervalId);
  //     if (iceGatheringStatusLabel) {
  //       iceGatheringStatusLabel.innerText = "";
  //     }
  //     if (signalingStatusLabel) {
  //       signalingStatusLabel.innerText = "";
  //     }
  //     if (iceStatusLabel) {
  //       iceStatusLabel.innerText = "";
  //     }
  //     if (peerStatusLabel) {
  //       peerStatusLabel.innerText = "";
  //     }
  //     console.log("stopped peer connection");
  //     // if (pc === peerConnection) {
  //     //   peerConnection = null;
  //     // }
  //   }

  //   const maxRetryCount = 3;
  //   const maxDelaySec = 4;

  //   async function fetchWithRetries(url, options, retries = 1) {
  //     try {
  //       return await fetch(url, options);
  //     } catch (err) {
  //       if (retries <= maxRetryCount) {
  //         const delay =
  //           Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) *
  //           1000;

  //         await new Promise((resolve) => setTimeout(resolve, delay));

  //         console.log(
  //           `Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`
  //         );
  //         return fetchWithRetries(url, options, retries + 1);
  //       } else {
  //         throw new Error(`Max retries exceeded. error: ${err}`);
  //       }
  //     }
  //   }

  return (
    <div id='content'>
      <div id='video-wrapper'>
        <div>
          <video
            id='video-element'
            autoPlay
            src='https://app.tailorgpt.ai/demo/brescia-musei/ReDesiderio_idle.mp4'
            loop
            muted='muted'
          ></video>
        </div>
      </div>

      {/* <br />

      <div id='buttons'>
        <button id='connect-button' type='button' onClick={connectButton}>
          Connect
        </button>
        <button
          id='start-button'
          type='button'
          onClick={() =>
            startButton(
              "Sono Re Desiderio, pronto ad aiutarvi con la mia saggezza e conoscenza, così come ho guidato il mio popolo, i Longobardi, con determinazione."
            )
          }
        >
          Start
        </button>
        <button id='destroy-button' type='button' onClick={destroyButton}>
          Destroy
        </button>
      </div> */}
    </div>
     
  );
};

export default VideoAssistente;
